// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "styles/application.css";
import JQuery from 'jquery'
window.$ = window.JQuery = JQuery;

import "jquery-validation"

var BeforeAfter = require("packs/beforeAfter")
global.BeforeAfter = BeforeAfter
window.BeforeAfter = BeforeAfter

require("common")
require("@fancyapps/fancybox")
require("magnific-popup")

require("packs/jquery.serializejson.min.js")
require("packs/jquery.validate.messages_ru.js")
require("packs/bafsy.js")
require("packs/scripts.min.js")

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
